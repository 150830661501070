import 'firebase/auth'
import './src/styles/global.scss'
import wrapWithProvider from './src/wrap-with-provider'

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (sessionStorage.getItem('pageViewed')) {
    sessionStorage.setItem(
      'pageViewed',
      parseInt(sessionStorage.getItem('pageViewed')) + 1
    )
  } else {
    sessionStorage.setItem('pageViewed', 1)
  }

  if (prevLocation && location.pathname !== prevLocation.pathname) {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'auto',
      })
    }, 1000)
  }
}

// export const wrapRootElement = ({ element }) => element
export const wrapRootElement = wrapWithProvider
