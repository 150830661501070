exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("./../../../src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-templates-get-residence-by-city-tsx": () => import("./../../../src/templates/get-residence-by-city.tsx" /* webpackChunkName: "component---src-templates-get-residence-by-city-tsx" */),
  "component---src-templates-home-services-tsx": () => import("./../../../src/templates/home-services.tsx" /* webpackChunkName: "component---src-templates-home-services-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-listing-residence-tsx": () => import("./../../../src/templates/listing-residence.tsx" /* webpackChunkName: "component---src-templates-listing-residence-tsx" */),
  "component---src-templates-residence-multiple-tsx": () => import("./../../../src/templates/residence-multiple.tsx" /* webpackChunkName: "component---src-templates-residence-multiple-tsx" */),
  "component---src-templates-residence-single-blog-tsx": () => import("./../../../src/templates/residence-single-blog.tsx" /* webpackChunkName: "component---src-templates-residence-single-blog-tsx" */),
  "component---src-templates-single-blog-tsx": () => import("./../../../src/templates/single-blog.tsx" /* webpackChunkName: "component---src-templates-single-blog-tsx" */),
  "component---src-templates-single-cms-tsx": () => import("./../../../src/templates/single-cms.tsx" /* webpackChunkName: "component---src-templates-single-cms-tsx" */),
  "component---src-templates-single-landing-residence-tsx": () => import("./../../../src/templates/single-landing-residence.tsx" /* webpackChunkName: "component---src-templates-single-landing-residence-tsx" */),
  "component---src-templates-single-page-tsx": () => import("./../../../src/templates/single-page.tsx" /* webpackChunkName: "component---src-templates-single-page-tsx" */),
  "component---src-templates-single-residence-tsx": () => import("./../../../src/templates/single-residence.tsx" /* webpackChunkName: "component---src-templates-single-residence-tsx" */),
  "component---src-templates-single-stay-tsx": () => import("./../../../src/templates/single-stay.tsx" /* webpackChunkName: "component---src-templates-single-stay-tsx" */)
}

