import React from 'react'
import { Provider } from 'react-redux'

// import { store } from './state/createStore'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import popinReducer from './state/popins'
import sfResidencesReducer from './state/salesforceResidences'

const rootReducer = combineReducers({
  popins: popinReducer,
  sfResidences: sfResidencesReducer,
})

const WrappedProvider = ({ element }) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: {
      popins: {
        popinList: [],
      },
      sfResidences: {
        residences: [],
      },
    },
  })

  return <Provider store={store}>{element}</Provider>
}

export default WrappedProvider
